import React, { useEffect } from 'react';
import NavBar from 'pages/agency-app/layout/NavBar';
import AgencyRouter from 'pages/agency-app/AgencyRouter';
import { SnackBar } from 'pages/common/SnackBar';
import Messages from 'services/i18n/Messages';
import { Link } from 'react-router-dom';
import { APPARTMENT, PARAMETERES_AGENCY } from 'routes/Routes';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { Routes } from 'routes/RoutesUtils';
import { AGENT_ID } from 'routes/QueryParams';
import { NONE } from 'services/filters/consts';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { DialogContent, DialogTitle } from '@material-ui/core';
import ProfileParameteresForm from 'pages/agency-app/parameters/profilParameters/ProfileParameteresForm';
import { useUserQueries } from 'network/queries/userQueries';
import { detectMobile } from 'services/utils';
import logo from 'assets/images/logo.png';
import DialogWrapper from 'pages/common/DialogWrapper';

function AgencyRoot() {
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const agentsQueries = useAgentsBackend();
  const { getAgent } = agentsQueries;
  const userQueries = useUserQueries();
  const { getUserAgents } = userQueries;
  const isAdmin = sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN);

  const { data: agency } = getCurrentAgency(isAdmin);
  const { data: agents } = getUserAgents();

  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: propertiesData } = getProperties();
  const { data: agent } = getAgent();

  useEffect(() => {
    if (agents) {
      // @ts-ignore
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'kyjnxdm8',
        name: sessionManager.getSession()?.user_name,
        email: sessionManager.getSession()?.user_mail,
        user_type: 'Agent',
        agencies_name: agents.map((data) => data.agency.name).join(', '),
        created_at: Date.now(),
      });
    }
  }, [agents]);

  const isMobile = detectMobile();

  if (isMobile) {
    return (
      <div className="agency-root">
        <div className="responsive-msg">
          <div className="logo-wrapper">
            <img className="logo" src={logo} alt="Settlesweet" />
          </div>
          <p>{Messages.t('info.notResponsive')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="agency-root">
      <NavBar />
      <div className="page-content">
        {
          isAdmin && agency && (!agency.notificationMail || agency.notificationMail === '') && (
            <SnackBar>
              {Messages.t('snack.noMailDefined')}
              <Link to={PARAMETERES_AGENCY}>{Messages.t('snack.noMailDefined.link')}</Link>
            </SnackBar>
          )
        }
        {
          agency && propertiesData
          && propertiesData.filter((property) => property.online && !property.agentID).length > 0
          && (
            <SnackBar>
              {
                agency.notificationMail
                  ? Messages.t('snack.missingAgent', { mail: agency.notificationMail || '' })
                  : Messages.t('snack.missingAgentNoMail')
              }
              <Link
                to={Routes.updateUrlWithQuery(APPARTMENT, [{ label: AGENT_ID, value: NONE }])}
              >
                {Messages.t('snack.missingAgent.link')}
              </Link>
            </SnackBar>
          )
        }
        {
          agent && !agent.createdForAdmin && (
            <DialogWrapper
              open={!agent.firstname || !agent.lastname}
              hideCloseBtn
              onClose={() => null}
            >
              <DialogTitle>{Messages.t('agent.welcome')}</DialogTitle>
              <DialogContent>
                <div>{Messages.t('agent.finalize')}</div>
                <ProfileParameteresForm agent={agent} />
              </DialogContent>
            </DialogWrapper>
          )
        }
        <AgencyRouter />
      </div>
    </div>
  );
}

export default AgencyRoot;
