import React, { ReactNode, useCallback, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

/* global google */

const gmapAPIKey = 'AIzaSyBhKZDJKZXIxjqEVBpWbeowCCtrMjydT8g';
export const MapContext = React.createContext<google.maps.Map | null>(null);

type Props = {
  children: ReactNode,
  center: Coordinates,
  zoom: number,
  style?: google.maps.MapTypeStyle[],
  onZoomChanged?: (zoomValue: number) => void,
};

export type Coordinates = {
  lat: number,
  lng: number,
};

export default function GoogleMap(
  {
    children,
    center,
    zoom,
    style,
    onZoomChanged,
  }: Props,
) {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const loader = new Loader({
    apiKey: gmapAPIKey,
    version: 'weekly',
  });

  const loadMap = useCallback((node: HTMLDivElement | null) => {
    if (!map && node) {
      loader.load().then(() => {
        const newMap = new google.maps.Map(node, {
          maxZoom: 20,
          center,
          zoom,
          gestureHandling: 'greedy',
          styles: style,
        });
        if (onZoomChanged) {
          newMap.addListener('zoom_changed', () => {
            const mapZoom = newMap.getZoom();
            if (mapZoom) {
              onZoomChanged(mapZoom);
            }
          });
        }
        setMap(newMap);
      });
    }
  }, [setMap, map]);

  return (
    <div className="google-map-container" ref={loadMap}>
      <MapContext.Provider value={map}>
        {map && children}
      </MapContext.Provider>
    </div>
  );
}
