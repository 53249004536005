import React, { useState } from 'react';
import {
  Page,
  Document,
} from 'react-pdf';
import Image from 'theme/Image';
import { IconButton } from '@material-ui/core';
import {
  Delete,
  Download,
} from '@material-ui/icons';
import FileDisplay from 'pages/common/FileDisplay';
import DialogWrapper from './DialogWrapper';

type Props = {
  src: string,
  isPdf?: boolean;
  onDelete?: () => void
  disabled?: boolean
};

export default function FilePreview(
  {
    src,
    isPdf,
    onDelete,
    disabled,
  }: Props,
) {
  const [showPreview, setShowPreview] = useState(false);
  return (
    <>

      <div role="presentation" onClick={() => setShowPreview(true)} className="file-preview">
        {
          isPdf || src.endsWith('.pdf') ? (
            <Document
              file={src}
            >
              <Page pageNumber={1} />
            </Document>
          ) : (
            <Image alt="document" src={src} />
          )
        }
        <div
          className="preview-actions-container"
        >
          <div
            className="preview-actions"
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a href={src} download>
              <IconButton>
                <Download />
              </IconButton>
            </a>
            {
              onDelete && (
                <IconButton disabled={disabled} onClick={onDelete}>
                  <Delete />
                </IconButton>
              )
            }
          </div>
        </div>
      </div>
      {
        showPreview && (
          <DialogWrapper open onClose={() => setShowPreview(false)}>
            <FileDisplay src={src} />
          </DialogWrapper>
        )
      }
    </>
  );
}
