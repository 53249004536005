import React, { useState } from 'react';
import Filters from 'pages/agency-app/appartments/appartments/Filters';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  APPARTMENT,
  APPARTMENT_ADD_PROPERTY,
  APPARTMENT_MAP,
  PROPERTY_ADD,
} from 'routes/Routes';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import PropertyMap from 'pages/agency-app/appartments/appartments/map/PropertyMap';
import PropertyList from 'pages/agency-app/appartments/appartments/PropertyList';
import { useObservable } from 'micro-observables';
import propertiesFilterService from 'services/filters/PropertiesFilterService';
import { Property, PropertyForm } from 'types/Property';
import { BasicApiResponse } from 'types/Api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PropertyByUrlForm } from 'types/forms/PropertyByUrlForm';
import {
  DialogActions,
  DialogContent,
  DialogTitle, Menu, MenuItem,
} from '@material-ui/core';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';
import { NotificationService } from 'lib/notification';
import { ADD_PROPERTY } from 'routes/QueryParams';
import DialogWrapper from 'pages/common/DialogWrapper';

export default function Appartments() {
  const history = useHistory();
  const [apiErrors, setApiError] = useState({});

  const [submitting, setSubmitting] = useState(false);
  const propertiesQueries = usePropertiesBackend();
  const { getProperties, createPropertyByUrl } = propertiesQueries;
  const location = useLocation<{ propertyUrl?: string, candidateId?: string }>();
  const queryParams = new URLSearchParams(location.search);

  const addProperty = queryParams.get(ADD_PROPERTY);
  const propertyUrl = location.state?.propertyUrl;
  const [candidateId, setCandidateId] = useState(location.state?.candidateId);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const propertiesFilter = useObservable(propertiesFilterService.getFilterObservable());

  const { data: propertiesData } = getProperties(propertiesFilter.isArchived);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PropertyByUrlForm>({
    defaultValues: {
      url: propertyUrl || undefined,
    },
  });

  const onSubmit: SubmitHandler<PropertyByUrlForm> = async (formData: PropertyByUrlForm) => {
    setSubmitting(true);
    createPropertyByUrl.mutateAsync(formData)
      .then((res: BasicApiResponse | PropertyForm) => {
        if ('status' in res && res.status === 202) {
          setApiError({ url: ['property.add.processing'] });
          NotificationService.notifyError(Messages.t('field.error.property.add.processing'));
        } else if (candidateId) {
          history.push(PROPERTY_ADD, { candidateId, propertyCreation: res });
          setApiError({});
        } else {
          history.push(PROPERTY_ADD, { propertyCreation: res });
          setApiError({});
        }
      })
      .catch((err) => {
        if (err.status === 202) {
          setApiError({ url: ['property.add.processing'] });
        } else if (err.status === 400) {
          setApiError({ url: ['property.add.notScrap'] });
        } else {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const properties: Property[] = [];
  if (propertiesData) {
    properties.push(...propertiesFilterService.applyFilters(propertiesData));
  }

  return (
    <>
      <DialogWrapper
        open={addProperty === 'true'}
        onClose={() => {
          setCandidateId(undefined);
          history.push(APPARTMENT);
        }}
      >
        <DialogTitle>{Messages.t('formButton.addPropertyByUrl')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="url"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  label={Messages.t('field.url')}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setCandidateId(undefined);
                history.push(APPARTMENT);
              }}
            >
              {Messages.t('formButton.cancel')}
            </Button>
            <SpinButton
              editing
              spin={submitting}
              title={Messages.t('formButton.confirm')}
            />
          </DialogActions>
        </form>
      </DialogWrapper>
      <div className="page-header">
        <div className="page-header-row">
          <Filters properties={propertiesData} />
          <Button
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {Messages.t('formButton.add')}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              disabled={submitting}
              onClick={() => {
                setAnchorEl(null);
                history.push(APPARTMENT_ADD_PROPERTY);
              }}
            >
              {Messages.t('property.addByUrl')}
            </MenuItem>
            <MenuItem
              onClick={() => history.push(PROPERTY_ADD)}
              disabled={submitting}
            >
              {Messages.t('property.addManually')}
            </MenuItem>
          </Menu>
        </div>
        <div className="page-selector-container">
          <Link
            className={`page-selector ${location.pathname === APPARTMENT ? 'selected' : ''}`}
            to={APPARTMENT}
          >
            {Messages.t('pageSelector.list')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === APPARTMENT_MAP ? 'selected' : ''}`}
            to={APPARTMENT_MAP}
          >
            {Messages.t('pageSelector.map')}
          </Link>
        </div>
      </div>
      <div className="page-body">
        {
          properties && (
            <Switch>
              <Route
                exact
                path={APPARTMENT}
                render={() => (
                  <PropertyList properties={properties} />
                )}
              />
              <Route
                exact
                path={APPARTMENT_MAP}
                render={() => (
                  <PropertyMap properties={properties} />
                )}
              />
            </Switch>
          )
        }
      </div>
    </>
  );
}
