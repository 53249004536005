import { FetchError, BackendAuthenticationError } from 'network/Errors';
import sessionManager from 'services/sessionManager';
// eslint-disable-next-line import/no-cycle
import sessionService from 'services/sessionService';

export default class FetchAdapter {
  // eslint-disable-next-line class-methods-use-this
  async fetch(url, params) {
    const response = await window.fetch(url, params);
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.log(url);
      // eslint-disable-next-line no-console
      console.log(params);
      if ((response.status === 403 || response.status === 401)
        && sessionManager.getRefreshToken()) {
        sessionService.refreshSession(sessionManager.getRefreshToken());
      }
      let jsonResponse = null;
      try {
        jsonResponse = await response.json();
      } catch (e) {
        // ignore
      }
      if (response.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new BackendAuthenticationError(jsonResponse);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new FetchError(jsonResponse, response.status);
      }
    }

    return response;
  }

  async fetchJson(url, params) {
    const response = await this.fetch(url, params);
    return response.json();
  }
}
