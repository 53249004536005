import React, { useMemo, useState } from 'react';
import { Note, NoteCreation } from 'types/Note';
import { Agent } from 'types/Agent';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';
import NoteItem from 'pages/common/NoteItem';

type Props = {
  notes: Note[]
  agents: Agent[]
  addNote: (message: string) => Promise<Note>
  editNote: (message: string, noteId: string) => Promise<Note>
  deleteNote: (noteId: string) => void
};

export default function Notes(
  {
    notes,
    addNote,
    agents,
    editNote,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteNote,
  }: Props,
) {
  const [apiErrors] = useState();
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<NoteCreation>();

  const formField = watch();

  const onSubmit: SubmitHandler<NoteCreation> = (formData: NoteCreation) => {
    setSubmitting(true);
    addNote(formData.message)
      .then(() => reset())
      .finally(() => {
        setSubmitting(false);
      });
  };

  const agentMap = useMemo(() => agents.reduce((acc, val) => {
    acc[val.id] = val;
    return acc;
  }, {}), [agents.length]) as { [key: string]: Agent };

  return (
    <div className="notes-container">
      <div className="note-list">
        {
          notes
            .sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || ''))
            .map((note) => (
              <NoteItem
                key={note.id}
                setSubmitting={setSubmitting}
                submitting={submitting}
                note={note}
                editNote={(message) => editNote(message, note.id || '')}
                deleteNote={() => deleteNote(note.id || '')}
                agentMap={agentMap}
              />
            ))
        }
      </div>
      <form className="add-note-form base-add" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="message"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              control={controller}
              label={Messages.t('field.note')}
            />
          )}
        />
        <SpinButton
          editing
          disabled={!formField.message}
          spin={submitting}
          title={Messages.t('formButton.add')}
        />
      </form>
    </div>
  );
}
