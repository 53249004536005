import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import Messages from 'services/i18n/Messages';
import { useDropzone } from 'react-dropzone';
import {
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close, CloudUpload } from '@material-ui/icons';
import { FileUpload } from 'types/FileUpload';
import Button from 'theme/Button';
import { Image } from 'types/Property';
import { ReactSortable } from 'react-sortablejs';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props<T> = {
  submitting: boolean,
  onValidate: (files: FileUpload[]) => void
  show: boolean,
  onDelete?: (imageId: string) => void
  onUpdate?: (images: T[]) => void,
  setShow: (show: boolean) => void,
  maxFiles?: number
  images?: T[]
};

type ImageUpdate<T> = {
  id: string,
  file?: (File & { preview: string }),
  image?: T
  order: number,
};

export default function ImageUpload<T extends Image>(
  {
    onDelete,
    onUpdate,
    submitting,
    onValidate,
    show,
    setShow,
    maxFiles,
    images,
  }: Props<T>,
) {
  const [files, setFiles] = useState<ImageUpdate<T>[]>(
    images?.map((image) => ({ image, order: image.order || 0, id: image.id })) || [],
  );

  useEffect(() => {
    if (show) {
      setFiles(images?.map((image) => ({ image, order: image.order || 0, id: image.id })) || []);
    }
  }, [show, setFiles, images?.length]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: maxFiles || 0,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const newFiles = dropedFiles.map((file, index) => ({
        order: files.length + index,
        id: file.name,
        filer: Object.assign(file, {
          preview: URL.createObjectURL(file),
          submiting: false,
        }),
      }));
      const filesResult = await Promise.all(newFiles.map(async (file) => ({
        filename: file.filer.name || '',
        fileBase64: await getBase64(file.filer),
        order: file.order,
      })));
      onValidate(filesResult);
    },
  });

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => {
    if (submitting) {
      return;
    }
    setShow(false);
  }, [submitting]);

  function getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  console.log(files);

  return (
    <>
      <Button
        roundedIcon
        className="add-image-icon-button"
        preventDefault
        variant="contained"
        onClick={handleShow}
      >
        <AiOutlineVideoCameraAdd />
      </Button>
      <DialogWrapper open={show} onClose={handleClose}>
        <DialogTitle>{Messages.t('image.addPhoto')}</DialogTitle>
        <DialogContent>
          <div {...getRootProps({ className: 'dropzone upload-image-zone' })}>
            {
              files.length > 1 && (
                <div>
                  {Messages.t('image.sort')}
                </div>
              )
            }
            <input {...getInputProps()} />
            {
              files.length > 0 ? (
                <>
                  <ReactSortable
                    className="sortable-list"
                    list={files}
                    filter=".ignore-elements"
                    setList={(f) => setFiles(f.map((file, index) => ({ ...file, order: index })))}
                    onEnd={() => onUpdate && onUpdate(
                      files
                        .filter((file) => !!file.image)
                        .map((file) => ({
                          ...(file.image as T),
                          order: file.order,
                        })),
                    )}
                    animation={200}
                    scroll={false}
                  >
                    {
                      files
                        .sort((a, b) => (
                          (((a.order !== null && a.order !== undefined) ? a.order : -1))
                          - ((b.order !== null && b.order !== undefined) ? b.order : -1)))
                        .map((file) => (
                          <div className="thumbnail-container" key={file.id}>
                            {
                              file.image && (
                                <img
                                  alt={file.image.imageURL}
                                  src={file.image.imageURL}
                                />
                              )
                            }
                            {
                              file.file && (
                                <img
                                  alt={file.file.name}
                                  src={file.file.preview}
                                />
                              )
                            }
                            <IconButton
                              disabled={submitting}
                              className="delete-button"
                              onClick={(e) => {
                                setFiles(
                                  (prevState) => prevState
                                    .filter((fileFilter) => file.id !== fileFilter.id),
                                );
                                if (file.image && onDelete) {
                                  onDelete(file.image.id);
                                }
                                e.stopPropagation();
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        ))
                    }

                  </ReactSortable>
                  <div className="thumbnail-upload-container ignore-elements">
                    <CloudUpload />
                    {Messages.t('dragnDrop.dropHere')}
                  </div>
                </>
              ) : (
                <div className="input-placeholder">
                  <CloudUpload />
                  {Messages.t('dragnDrop.dropHere')}
                </div>
              )
            }
          </div>
        </DialogContent>
      </DialogWrapper>
    </>
  );
}
