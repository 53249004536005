import { observable, WritableObservable } from 'micro-observables';
import { RefreshSession, Session } from 'types/security/session';

const TOKEN_KEY = 'TOKEN_AUTH';

export class SessionManager {
  private jwtToken: WritableObservable<string | undefined> = observable(undefined);

  getCurrentToken() {
    return this.jwtToken?.get();
  }

  // eslint-disable-next-line class-methods-use-this
  private parseJwt<T>(token: string | undefined): T | null {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
      return JSON.parse(jsonPayload);
    }
    return null;
  }

  getSession(): Session | null {
    const token = this.jwtToken?.get();
    return this.parseJwt<Session>(token);
  }

  getCurrentTokenObservable() {
    return this.jwtToken;
  }

  setCurrentToken(token: string | undefined) {
    this.jwtToken.set(token);
  }

  // eslint-disable-next-line class-methods-use-this
  setRefreshToken(token: string | undefined) {
    if (!token) {
      localStorage.removeItem(TOKEN_KEY);
    } else {
      localStorage.setItem(TOKEN_KEY, token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getRefreshToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  getRefreshSession(token: string): RefreshSession | null {
    return this.parseJwt<RefreshSession>(token || undefined);
  }
}

const sessionManager = new SessionManager();
export default sessionManager;
