import React, { useState } from 'react';
import { Property } from 'types/Property';
import GoogleMap from 'lib/googleMap/GoogleMap';
import GoogleCluster, { ClusterEvent } from 'lib/googleMap/GoogleCluster';
import GoogleMarker from 'lib/googleMap/GoogleMarker';
import GoogleOverlay from 'lib/googleMap/GoogleOverlay';
import CardCarrousel from 'lib/CardCarrousel';
import ClusterCard from 'pages/agency-app/appartments/appartments/map/ClusterCard';
import PropertyCard from 'pages/agency-app/appartments/appartments/map/PropertyCard';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_DETAIL } from 'routes/Routes';

type Props = {
  properties: Property[],
};

const getPropertyPosition = (property: Property | null) => ({
  lng: property?.longitude || 0,
  lat: property?.latitude || 0,
});

export default function PropertyMap({ properties }: Props) {
  const history = useHistory();
  const filteredProperties = properties
    .filter((property) => property.latitude && property.longitude);
  const [
    clusterSelectedPropertyList,
    setClusterSelectedPropertyList,
  ] = useState<ClusterEvent | null>(null);
  const [
    selectedProperty,
    setSelectedProperty,
  ] = useState<Property | null>(null);

  const filteredPropertiesTooltip = (clusterSelectedPropertyList
    && properties.filter((property) => clusterSelectedPropertyList
      .markerIds.includes(property.id.toString()))) || [];

  const selectedCenter = getPropertyPosition(selectedProperty);

  if (filteredProperties.length === 0) {
    return <div>no properties</div>;
  }

  const goToProperty = (property: Property) => {
    history.push(Routes.withPath(PROPERTY_DETAIL, [{ label: ID, value: property.id }]));
  };

  return (
    <GoogleMap center={getPropertyPosition(filteredProperties[0])} zoom={15}>
      <GoogleCluster
        onClick={(cluster) => {
          setClusterSelectedPropertyList(cluster);
        }}
      >
        {
          filteredProperties.map((property) => (
            <React.Fragment key={property.id}>
              <GoogleMarker
                id={property.id}
                coordinates={getPropertyPosition(property)}
                onClick={() => setSelectedProperty(property)}
                className="map-marker"
                text={`${property.price} €`}
              />
            </React.Fragment>
          ))
        }
        <GoogleOverlay
          isOpen={!!selectedProperty}
          onClose={() => setSelectedProperty(null)}
          center={selectedCenter}
        >
          {
            selectedProperty && (
              <PropertyCard
                property={selectedProperty}
                onClick={() => goToProperty(selectedProperty)}
              />
            )
          }
        </GoogleOverlay>
        <GoogleOverlay
          onClose={() => setClusterSelectedPropertyList(null)}
          coordinates={clusterSelectedPropertyList?.center}
          isOpen={!!clusterSelectedPropertyList}
        >
          <div className="carrousel-container">
            <CardCarrousel listLength={filteredPropertiesTooltip.length}>
              {
                filteredPropertiesTooltip
                  .map((property) => (
                    <ClusterCard
                      key={property.id}
                      property={property}
                      onClick={() => goToProperty(property)}
                    />
                  ))
              }
            </CardCarrousel>
          </div>
        </GoogleOverlay>
      </GoogleCluster>
    </GoogleMap>
  );
}
