import React, { useState } from 'react';
import 'firebase/compat/auth';
import SpinButton from 'theme/SpinButton';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NotificationService } from 'lib/notification';
import { AgencyCreation } from 'types/AgencyCreation';
import { agencyApi } from 'network/api/AgencyApi';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';

export default function AgencyCreationFrom() {
  const [apiErrors, setApiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [agencyData, setAgencyData] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgencyCreation>();

  const onSubmit: SubmitHandler<AgencyCreation> = (formData: AgencyCreation) => {
    setSubmitting(true);
    setApiErrors({});
    agencyApi.createAgency(formData)
      .then((datas) => {
        setAgencyData(JSON.stringify(datas));
        NotificationService.notifySuccess(Messages.t('notifications.mailSend'));
      })
      .catch((error) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
        setApiErrors(error.json_response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="login-page">
      <h1>SETTLESWEET PRO</h1>
      <div className="login-card">
        <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="agencyName"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.agencyName')}
              />
            )}
          />
          <Controller
            name="email"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.adminEmail')}
              />
            )}
          />
          <Controller
            name="adPhoneNumber"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <PhoneNumberInputWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.adPhoneNumber')}
              />
            )}
          />
          <Controller
            name="imageUrl"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.imageUrl')}
              />
            )}
          />
          <SpinButton
            editing
            className="connection-button"
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
      </div>
      <div>{agencyData}</div>
    </div>
  );
}
