import JSZip from 'jszip';
import {
  DocumentDataMap,
  DocumentSection,
  documentSection,
  DocumentTag, documentTagEnum,
} from 'types/DocumentData';
import { saveAs } from 'file-saver';
import Messages from './i18n/Messages';

export default class FilesUtils {
  static urlToPromise(url) {
    return fetch(
      url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' },
      },
    ).then((r) => r.blob());
  }

  public static getFileName(link: string | undefined): string {
    if (!link) {
      return '';
    }
    return decodeURI(link.split('/').slice(-1)[0] || '');
  }

  public static async generateZipFiles(
    map: DocumentDataMap,
    nameMap: { [key: string]: string },
    mainContactName: string,
    updatePercentCallback?: (percent: number) => void,
  ): Promise<void> {
    const zip = new JSZip();
    Object.keys(nameMap).forEach((key) => {
      const folder = Object.keys(nameMap).length > 1 ? zip.folder(nameMap[key]) : zip;
      if (folder && map[key]) {
        Object.keys(documentSection)
          .filter((section) => documentSection[section]
            .some((tag) => tag !== documentTagEnum.PENDING))
          .forEach((section) => {
            const filteredTagList = documentSection[section]?.filter((tag) => tag
              !== documentTagEnum.PENDING
              && map[key][tag]?.filter((document) => document.link).length > 0);
            if (filteredTagList.length === 0) {
              return;
            }
            const sectionFolder = folder.folder(Messages.t((`document.section.${section as DocumentSection}`)));
            if (sectionFolder) {
              filteredTagList.forEach((tag) => {
                const tagFolder = filteredTagList.length > 1 ? sectionFolder.folder(Messages.t((`document.tag.${tag as DocumentTag}`))) : sectionFolder;
                if (tagFolder) {
                  map[key][tag].forEach((document) => {
                    if (document.link) {
                      tagFolder.file(
                        FilesUtils.getFileName(document.link),
                        FilesUtils.urlToPromise(document.link),
                        { binary: true },
                      );
                    }
                  });
                }
              });
            }
          });
      }
    });
    const blob = await zip.generateAsync({ type: 'blob' }, (metadata) => {
      if (updatePercentCallback) {
        updatePercentCallback(Math.round(metadata.percent) || 0);
      }
    });
    saveAs(blob, `${mainContactName}.zip`);
  }
}
