import React, { useState } from 'react';
import imagePlaceHolder from 'assets/images/imagePlaceholder.svg';

type Props = {
  src?: string
  alt: string
};

export default function Image({ src, alt }: Props) {
  const [hasError, setHasError] = useState(false);
  if (hasError || !src) {
    return (<img src={imagePlaceHolder} alt={alt} />);
  }
  return (
    <img onError={() => setHasError(true)} src={src} alt={alt} />
  );
}
