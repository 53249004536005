import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { UserAdmin } from 'types/admin/UserAdmin';

export function useUserAdminQueries() {
  const queryClient = useQueryClient();

  const GetAllUser = (enabled = true) => useQuery<UserAdmin[]>(
    ['getAllUsersAdmin', sessionManager.getSession()?.user_id],
    async () => {
      const response: UserAdmin[] = await backend.fetchJson(
        '/admin/users',
      );
      return response;
    },
    { enabled },
  );

  const GiveAdminAcces = useMutation<void, any, {
    userId: string,
  }>(
    async ({ userId }) => {
      const response = await backend.post(`/admin/users/${userId}/admin`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAllUsersAdmin', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const RevokeAdminAcces = useMutation<void, any, {
    userId: string,
  }>(
    async ({ userId }) => {
      const response = await backend.post(`/admin/users/${userId}/revoke`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAllUsersAdmin', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getAllUser: GetAllUser,
    revokeAdminAcces: RevokeAdminAcces,
    giveAdminAcces: GiveAdminAcces,
  };
}
