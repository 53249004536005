import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import settings from 'services/settings';
import { Integrations } from '@sentry/tracing';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { ToastContainer } from 'react-toastify';
import { FirebaseAppProvider } from 'reactfire';
import { fr } from 'date-fns/locale';
import Messages from 'services/i18n/Messages';
import frTranslation from 'services/i18n/fr';
import {
  pdfjs,
} from 'react-pdf';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-phone-number-input/style.css';

import 'scss/app.scss';

import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';

import * as FullStory from '@fullstory/browser';

FullStory.init({ orgId: 'o-1BE731-na1' });

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBhKZDJKZXIxjqEVBpWbeowCCtrMjydT8g',
  authDomain: 'settlesweet-hunter.firebaseapp.com',
  databaseURL: 'https://settlesweet-hunter.firebaseio.com',
  projectId: 'settlesweet-hunter',
  storageBucket: 'settlesweet-hunter.appspot.com',
  messagingSenderId: '434059086365',
  appId: '1:434059086365:web:96ba0cb62839327f7954f4',
  measurementId: 'G-Q0N0MJLD47',
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

if (settings.env !== 'LOCAL') {
  Sentry.init({
    environment: settings.env,
    dsn: 'https://a302321778a5450c932af145827d7b30@o962745.ingest.sentry.io/6599745',
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

Messages.setAvailableTranslation([frTranslation]);

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      light: '#3d3d3d',
      main: '#373737',
      dark: '#313131',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#f5f5f5',

    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <App />
            </QueryClientProvider>
          </FirebaseAppProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
